import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/Twitter';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { LineIcon } from '@app/components/Icon/lineIcon';
import { SocialPlatforms } from '@app/types/user';

type SocialLoginButtonProps = {
  disabled: boolean;
  onClick: () => void;
  // SNSのプラットフォーム
  platform: SocialPlatforms;
  sx?: object;
  text: string;
};

export const SocialLoginButton: React.FC<SocialLoginButtonProps> = ({
  text,
  platform,
  disabled,
  onClick,
  sx,
}) => {
  const getPlatformStyles = (platform: SocialPlatforms) => {
    switch (platform) {
      case SocialPlatforms.Facebook:
        return {
          backgroundColor: '#1877F2',
          hoverColor: '#166FE5',
          icon: <FacebookIcon sx={{ color: '#fff', fontSize: 28 }} />,
        };
      case SocialPlatforms.Google:
        return {
          backgroundColor: '#DB4437',
          hoverColor: '#C53929',
          icon: <GoogleIcon sx={{ color: '#fff', fontSize: 28 }} />,
        };
      case SocialPlatforms.Instagram:
        return {
          background:
            'linear-gradient(45deg, #f58529, #dd2a7b, #8134af, #515bd4)', // Instagramの公式グラデーション
          hoverColor:
            'linear-gradient(45deg, #f77737, #c13584, #833ab4, #405de6)',
          // Instagramのグラデーション
          icon: <InstagramIcon sx={{ color: '#fff', fontSize: 28 }} />, // ホバー時のグラデーション
        };
      case SocialPlatforms.Line:
        return {
          background: '#06C755', // LINE's official green gradient
          hoverColor: '#06C755',
          icon: <LineIcon />, // ホバー時のグラデーション
        };
      case SocialPlatforms.Twitter:
        return {
          background:
            'linear-gradient(45deg, #1DA1F2, #1A91DA, #1A91DA, #1DA1F2)', // Twitter's blue gradient
          hoverColor:
            'linear-gradient(45deg, #1A91DA, #1DA1F2, #1A91F2, #1A91DA)',
          // Instagramのグラデーション
          icon: <XIcon sx={{ color: '#fff', fontSize: 28 }} />,
        };
      default:
        return null;
    }
  };
  const platformStyles = getPlatformStyles(platform);
  return (
    <Button
      variant="contained"
      disabled={disabled}
      onClick={onClick}
      sx={{
        '&:hover': {
          backgroundColor: platformStyles?.hoverColor,
        },
        alignItems: 'center',
        background: disabled
          ? '#ccc'
          : platformStyles?.background || platformStyles?.backgroundColor,

        borderRadius: '8px',

        color: '#fff',

        display: 'flex',

        justifyContent: 'flex-start',

        maxWidth: '320px',

        mb: 2,

        padding: '10px',

        textTransform: 'none',
        width: '100%',
        ...sx,
      }}
    >
      <Box // 左寄せのアイコン
        component="span"
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          marginRight: '12px',
        }}
      >
        {platformStyles?.icon}
      </Box>
      <Typography
        variant="inherit"
        sx={{
          flexGrow: 1,

          fontSize: '16px',

          fontWeight: 600,

          lineHeight: 1.2,

          textAlign: 'center',
          whiteSpace: 'nowrap',
        }}
      >
        {text}
      </Typography>
    </Button>
  );
};
